import YAML from 'yamljs'
import { handle } from 'web-ui-blocks/itc'
import { download, upload } from 'web-ui-blocks/io'
import { get, set } from 'object-path-immutable'
import { debounce } from 'lodash';
import { html } from 'lit-html'
import * as monaco from "monaco-editor"
self.MonacoEnvironment = {
	getWorkerUrl: function (moduleId, label) {
		if (label === 'json') {
			return './monaco-workers/json.worker.js';
		}
		return './monaco-workers/editor.worker.js';
	},
};

import React from 'react'
import ReactDOM from 'react-dom'
import App from './app';


const appContainer = document.querySelector("#app");
const KNOWN_SCHEMAS_KEY = 'SHAKY_JSON_SCHEMAS'
let knownSchemas = [
	"https://vega.github.io/schema/vega/v5.json",
]
try {
	knownSchemas = JSON.parse(localStorage[KNOWN_SCHEMAS_KEY])
} catch (err) { }
const url = new URL(document.URL)
if (url.searchParams.has('schema')) {
	knownSchemas = [
		url.searchParams.get('schema'),
	]
}


let store = {
	schema: null,
	json: null,
	status: '',
	details: '',
	knownSchemas
}
import Ajv from "ajv"
import addFormats from "ajv-formats"
import Yaml from 'yamljs';


const ajv = new Ajv({ allErrors: true, verbose: true })
addFormats(ajv)

let validatorSchemaUrl
let schemaProblem = null
let validateJson = (json) => true
let lastValidationDate = 0

function prettifyErrors(errors) {
	let maxLen = 0
	let step = 10
	let lines = errors.map((e, i) => {
		let instancePath = i === 0 || e.instancePath !== errors[i - 1].instancePath ? e.instancePath : ''.padEnd(e.instancePath.length)
		return { prefix: `${instancePath} ${e.message} (${JSON.stringify(e.params)})`, suffix: e.schemaPath }
	})
	for (let l of lines) {
		maxLen = Math.max(maxLen, l.prefix.length + step)
	}
	maxLen = Math.ceil(maxLen / step) * step
	lines = lines.map(l => l.prefix.padEnd(maxLen, ' ') + l.suffix)
	return lines.join("\n")
}

function getSchemaUrl(str) {
	try {
		const json = JSON.parse(str)
		const schemaUrl = json.$schema
		return schemaUrl
	} catch (err) {

	}
}


async function validate() {
	console.log('validation')
	let date = Date.now()
	let status = 'valid'
	let details
	if (date === lastValidationDate)
		return
	const value = editor.getValue()

	//   "$schema": "https://probe.beap.ovh/schema.json",

	let schemaUrl = getSchemaUrl(value)
	console.log('schemaUrl', schemaUrl)
	try {
		let json = JSON.parse(value)
		if (schemaUrl && (schemaUrl !== validatorSchemaUrl)) {
			try {
				console.log('new schema', schemaUrl)
				const schema = await fetch(schemaUrl).then(r => r.json())
				if (schema.$schema.endsWith('#'))
					schema.$schema = schema.$schema.split('#')[0]
				validateJson = ajv.compile({ ...schema, $id: undefined })
				validatorSchemaUrl = schemaUrl
				schemaProblem = null
				console.log('can validate  !')
			} catch (err) {
				console.log('failed to load schema', err.toString())
				validatorSchemaUrl = schemaUrl
				validateJson = null
				schemaProblem = err.toString()
			}
		}
		if (validateJson) {
			let valid = validateJson(json)
			if (!valid) {
				status = 'invalid-structure'
				const { errors } = validateJson
				details = prettifyErrors(errors)
				details = errors
			}
		} else {
			status = 'invalid-schema'
			details = schemaProblem
		}

	} catch (err) {
		status = 'invalid-json'
		details = err.toString()
	}
	console.log(details)
	lastValidationDate = date
	if (schemaUrl) {
		update(['schema'], schemaUrl)
	}

	update(['status'], status)
	update(['details'], details)
}

const requestValidation = debounce(validate, 100)


// import jsonSchema04 from './builtin-schemas/json-schema.draft-04.json'
let editor
monaco.languages.json.jsonDefaults.setDiagnosticsOptions({
	validate: true,
	enableSchemaRequest: true,
	schemas: [
		// {
		// 	uri: "http://json-schema.org/draft-04/schema#",
		// 	schema: jsonSchema04
		// }
	]
})
editor = monaco.editor.create(document.querySelector("#editor"), {
	value: JSON.stringify(store.json, null, 2),
	language: 'json',
	automaticLayout: true,
	theme: 'vs-dark',
	// scrollBeyondLastLine: false,
	wordWrap: 'wordWrapColumn',
	wordWrapColumn: 60,
	glyphMargin: true

});
editor.addCommand(monaco.KeyMod.CtrlCmd | monaco.KeyCode.KEY_S, function () {
	console.log('SAVE pressed!');
	handleSend()
})

window._editor = editor
editor.onDidChangeModelContent(e => {
	requestValidation()
})



function render() {
	ReactDOM.render(
		<App
			handleSend={handleSend}
			handleDownloadJson={handleDownloadJson}
			handleDownloadYaml={handleDownloadYaml}
			handleUploadJson={handleUploadJson}
			handleSchema={handleSchema}
			store={store}
			update={update}
		/>,
		appContainer
	);
}
function update(path, value) {
	try {

		switch (path[0]) {
			case 'json':
				const str = JSON.stringify(value, null, 2)
				const schema = value.$schema
				if (schema && schema !== store.schema)
					update(['schema'], schema)
				editor.setValue(str)

				break;
			case 'schema':
				let { knownSchemas } = store
				knownSchemas = [value, ...knownSchemas.filter((s) => s !== value)].filter((s, i) => i < 5)
				update(['knownSchemas'], knownSchemas)
				break;
			case 'knownSchemas':
				localStorage[KNOWN_SCHEMAS_KEY] = JSON.stringify(value)
				break;

			default:
				break;
		}
	} catch (err) {
		console.log('failed to update ', path[0], err.toString())
	}
	store = set(store, path, value);
	render()

}

function handleSchema(url) {
	try {
		const value = editor.getValue()
		let json = JSON.parse(value)
		json = { ...json, $schema: url }
		update(['json'], json)
	} catch (err) {
		console.log(err.toString())
	}
}
import vegaSpec from './builtins/treemap.vg.json'
const builtins = [vegaSpec].map(cfg => ({ raw: new Blob([JSON.stringify(cfg)], { type: 'application/json' }), source: 'builtin', name: "vega" }))
import launchSimpleTool from 'web-ui-blocks/simple-tool/src'
async function launch() {
	const updateTool = await launchSimpleTool({
		name: 'json-editor',
		async setup(json, name) {
			console.log('using config', json)
			update(['json'], json)

		},
		builtins,
		sources: ['url', 'gdrive']
	})
}



update(['json'], {
	$schema: knownSchemas[0]
})

launch().then(() => console.log('app launched'))

function handleSend() {
	const value = editor.getValue();
	try {
		const json = JSON.parse(value)
		const blob = new Blob([JSON.stringify(json)], { type: 'application/json' })
		if (!store.channel) {
			throw 'app is not connected'
		}
		store.channel.send(blob)
	} catch (err) {
		console.log('invalid JSON')
	}
}


function handleDownloadJson() {
	const value = editor.getValue();
	const blob = new Blob([value], { type: "application/json" })
	download("data.json", blob)
}
function handleDownloadYaml() {
	const value = editor.getValue();
	const json = JSON.parse(value)
	const yaml = YAML.stringify(json)
	const blob = new Blob([yaml], { type: "text/yaml" })
	download("data.yaml", blob)
}
async function handleUploadJson() {
	const files = await upload()
	const file = files[0]
	if (file) {
		const text = await file.text()
		let json
		try {
			json = JSON.parse(text)
		} catch (err) { }
		try {
			json = Yaml.parse(text)
		} catch (err) { }
		editor.setValue(JSON.stringify({ ...json, $schema: json.schema || store.schema || undefined }, null, 2))
	}
}
