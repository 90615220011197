import React, { Fragment, useRef } from 'react'
import * as JsonPointer from 'json-pointer'
import './style.css'
import { get } from 'json-pointer'
const USELESS_WORDS = ['schema', 'json', '']
const extractSchemaName = (schema) => {
    try {
        const url = new URL(schema)
        const path = url.pathname
        let name = path
        name = name.split('/').filter(part => USELESS_WORDS.indexOf(part) < 0).join('/')
        name = name.split('.').filter(part => USELESS_WORDS.indexOf(part) < 0).join('.')
        return name || schema
    } catch (err) {
        console.log('failed to extract schema name', err.toString())
        return '??'
    }
}

function renderDetails(details, schema) {
    if (!Array.isArray(details)) return details
    let errors = details
    let maxLen = 0
    let step = 10
    let lines = errors.map((e, i) => {
        let instancePath = i === 0 || e.instancePath !== errors[i - 1].instancePath ? e.instancePath : ''.padEnd(e.instancePath.length)
        return { ...e, prefix: `${instancePath} ${e.message} (${JSON.stringify(e.params)})` }
    })
    for (let l of lines) {
        maxLen = Math.max(maxLen, l.prefix.length + step)
    }
    maxLen = Math.ceil(maxLen / step) * step
    // title={JSON.stringify(get(schema, l.schemaPath), null, 2)}
    return (<Fragment>{lines.map((l, i) => <pre key={i} title={l.parentSchema ? JSON.stringify(l.parentSchema, (k, v) => {
        if (!k || Number.isFinite(Number(k)) || [l.schema[0], "type", "properties", "required", "additionalProperties"].includes(k))
            return v
        else return '...'
    }
        , 2) : ''
    } >{l.prefix.padEnd(maxLen, ' ') + l.schemaPath}</pre>)}</Fragment>)
}



export default function App(props) {
    const schemaUrlRef = useRef()
    return (
        <div className={'app ' + props.store.status}>

            <div className="json">
                <div className="status" >
                    {props.store.status || 'valid'}
                    <pre className="details">{renderDetails(props.store.details, props.store.schema)}</pre>
                </div>

                <div className="controls">

                    <button disabled={props.store.status === 'invalid-json'} id="download-yml" onClick={props.handleDownloadYaml}>dl yaml</button>
                    <button disabled={props.store.status === 'invalid-json'} id="download" onClick={props.handleDownloadJson}>dl json</button>
                    <button id="upload" onClick={props.handleUploadJson}>upload</button>
                </div>
            </div>
            <div className="schema">
                {
                    props.store.schema ? <div>{extractSchemaName(props.store.schema)}</div> : (<div>no schema</div>)
                }

                <div className="controls">
                    <select name="" onChange={e=>props.handleSchema(e.target.value)}>{props.store.knownSchemas.map(s=>(<option key={s} value={s}>{extractSchemaName(s)}</option>))}</select>
                    {/* <input ref={schemaUrlRef} type="url"  placeholder={props.store.schema || "enter your schema url..."}></input> */}
                    {/* <button disabled={props.store.status === 'invalid-json'} onClick={e => props.handleSchema(schemaUrlRef.current.value)}>confirm</button> */}
                </div>
            </div>

            <div className="status">
                {props.store.flavor}
            </div>
        </div>

    )
}